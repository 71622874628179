<template>
  <div class="explain">
    <div class="bg">
      <div class="center">
        <h4>视频讲解</h4>
        <p>Video Explanation</p>
      </div>
    </div>
    <div class="no-video">
      <div class="tit" v-show="novideo">
        <ul>
          <li v-for="item in list" :key="item.id" @click="change(item)" :class='item.id==selected?"selected":""'>{{item.name}}</li>
        </ul>
        <div class="search">
          <el-input v-model="search" prefix-icon="el-icon-search"></el-input>
        </div>
      </div>
      <div class="container bottom" v-show="novideo">
        <ul>
          <li @click="play(item.videosrc)" v-for="item in videolist" :key="item.id">
            <div class="top">
              <img :src="item.imgsrc" alt="">
              <div class="info">{{item.name}}</div>
            </div>
            <p>{{item.info}}</p>
          </li>
        </ul>
      </div>
      <!-- <div id="mask" ref="mask" v-show="!novideo"> -->
        <!-- <i class="el-icon-close" @click="closeplay"></i> -->
        <!-- <div class="pink"><video src="../../assets/video/黑洞.mp4" muted autoplay loop controls id="dplayer"></video></div> -->
      <!-- </div> -->
      <div class="video" v-show="!novideo">
        <i class="el-icon-close" @click="closeplay"></i>
        <video :src="src" muted autoplay loop controls id="dplayer">
      </video></div>
    </div>

  </div>
</template>

<script>
import video1 from '../../assets/video/PAK转换发布.mp4'
import video2 from '../../assets/video/图形引擎操作演示.mp4'
import img1 from '../../assets/image/service/视频讲解/转换.png'
import img2 from '../../assets/image/service/视频讲解/上传 (1).png'
import img3 from '../../assets/image/service/视频讲解/操作.png'
import img4 from '../../assets/image/service/视频讲解/编辑.png'
import img5 from '../../assets/image/service/视频讲解/地图.png'

  export default {
    data() {
      return {
        list: [
          { id:1, name:'黑洞图形引擎'},
          // { id:2, name:'星云协同平台'},
          // { id:3, name:'星系建管平台'}
        ],
        selected: 1,
        activeName: 'first',
        search: '',
        novideo: true,
        src: '',
        videolist: [
          {id: 1, name: '模型转换演示', info: 'Revit、3DSMAX、Bently模型转换.pak', imgsrc: img1, videosrc: ''},
          {id: 2, name: 'PAK格式上传', info: '中间格式模型上传、引擎准换及发布', imgsrc: img2, videosrc: video1},
          {id: 3, name: '图形引擎操作', info: '轻量化引擎模型浏览及引擎工具使用操作', imgsrc: img3, videosrc: video2},
          {id: 4, name: '虫洞编辑器操作', info: '对已上传的模型进行空间位置及其大小进行编辑操作', imgsrc: img4, videosrc: ''},
          {id: 5, name: '特殊模型处理', info: 'OSGB地形、点云数据及卫星图等特殊格式模型转PAK格式', imgsrc: img5, videosrc: ''}
        ]
      };
    },
    methods: {
      handleClick(tab, event) {
        console.log(tab, event)
      },
      change (item) {
        this.selected=item.id;
      },
      play(src) {
        // this.$refs.mask.style.display = 'block'
        // this.$refs.mask.style.height = document.getElementById('app').clientHeight + 'px'
        // this.$refs.mask.style.height = document.querySelector('.no-video').clientHeight + 'px'
        if(!src) return false
        this.src = src
        this.novideo = false
      },
      closeplay () {
        this.novideo = true
      }
    },
    mounted () {
    }
  };
</script>

<style lang="less" scoped>
*{
  box-sizing: border-box;
}
.explain{
  // height: 100%;
  .bg{
    background: url('../../assets/image/background/bg (6).jpg') center center;
    height: 60vh;
    background-size: cover;
    color: #fff;
    text-align: center;
    font-size: 60px;
    position: relative;
    p{
      font-size: 30px;
    }
    h4{
      font-family: "siyuan";
    }
  }
  // margin-top: 50px;
  .no-video{
    // height: 80vh;
    position: relative;
    .video{
      width: 70%;
      height: 100%;
      margin: 50px auto;
      video{
        width: 100%;
        height: 90%;
      }
      i{
        position: absolute;
        top: -30px;
        right: 50px;
        font-size: 30px;
      }
    }
  }
  .tit{
    width: 100%;
    height: 50px;
    line-height: 50px;
    padding: 0 50px 0px 100px;
    border-bottom: 1px solid #ccc;
    // background-color: pink;
    overflow: hidden;
    ul{
      width: calc(100% - 200px);
      float: left;
      overflow: hidden;
      li{
        float: left;
        padding: 0px 20px;
        background-color: #dddddd;
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
        cursor: pointer;
      }
    }
    .search{
      float: right;
      width: 200px;
      /deep/ .el-input__inner{
        border-radius: 20px;
      }
    }
  }
  .bottom{
    ul{
      margin: 80px 0;
      overflow: hidden;
      li{
        float: left;
        width: 30%;
        border: 1px solid #ccc;
        height: 120px;
        margin-right: 3%;
        margin-top: 30px;
        padding: 15px;
        cursor: pointer;
        @media screen and (max-width:992px) {
          width: 80%;
          float: none;
          margin: 30px auto;
        }
        .top{
          overflow: hidden;
          img{
            padding: 5px;
            float: left;
            width: 60px;
          }
          .info{
            float: left;
            margin-top: 15px;
            font-size: 20px;
            margin-left: 10px;
          }
        }
        p{
          // margin-left: 10px;
          margin-top: 5px;
        }
      }
    }
  }
}
.selected{
  background-color: #fff !important;
  border-bottom: 1px solid #ccc;
}
</style>
